import { useMemo } from "react";
import PropTypes from "prop-types";

import { getProperty } from "@gh/shared";

import * as styles from "./video-cover.module.scss";

export const VideoCover = ({ videos, className }) => {
  const wrapperClassName = useMemo(() => [styles.wrapper, className].join(" "), [className]);

  return (
    <video
      loop={true}
      muted={true}
      preload="auto"
      autoPlay={true}
      playsInline={true}
      className={wrapperClassName}
    >
      {videos.map((video) => {
        const src = getProperty(video, "publicURL");
        const extension = getProperty(video, "extension");

        return <source key={src} src={src} type={`video/${extension}`} />;
      })}
    </video>
  );
};

VideoCover.defaultProps = {
  videos: [],
  poster: null,
  className: "",
};

VideoCover.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  poster: PropTypes.object,
  className: PropTypes.string,
};
