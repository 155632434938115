import PropTypes from "prop-types";

import { CarouselGallery } from "../../../common/carousel-gallery";

export const Carousel = ({ data }) => <CarouselGallery images={data} />;

Carousel.defaultProps = {
  data: [],
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
