import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import cssEscape from "css.escape";

import {
  Container,
  ContainerToMD,
  Title,
  TipIconDE,
  TipIconEN,
  getProperty,
  useLocale,
  XS,
  SM,
  MD,
  LG,
  XL,
} from "@gh/shared";

import { ButtonPrev, ButtonNext } from "../../../common/nav-button";

import * as styles from "./carousel.module.scss";

const prevEl = `.${cssEscape(styles.prev)}`;
const nextEl = `.${cssEscape(styles.next)}`;

const ICON = {
  de: <TipIconDE className={styles.icon} />,
  en: <TipIconEN className={styles.icon} />,
};

export const Carousel = ({ data }) => {
  const { locale } = useLocale();

  return (
    <Fragment>
      <Container>
        <div className={styles.buttons}>
          <ButtonPrev className={styles.prev} />
          <ButtonNext className={styles.next} />
        </div>
      </Container>

      <Swiper
        spaceBetween={60}
        slidesPerView={1}
        slidesPerGroup={1}
        preventClicks={false}
        className={styles.slider}
        preventClicksPropagation={false}
        breakpoints={{
          [XS]: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          [SM]: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          [MD]: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          [LG]: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
          [XL]: {
            slidesPerView: 6,
            slidesPerGroup: 6,
          },
        }}
        pagination={{
          clickable: true,
          lockClass: "carousel-activities-locked",
          bulletClass: "carousel-activities-bullet",
          horizontalClass: "carousel-activities-bullets",
          bulletActiveClass: "carousel-activities-bullet-active",
        }}
        navigation={{ prevEl, nextEl, lockClass: styles.locked }}
        modules={[Pagination, Navigation]}
        centerInsufficientSlides={true}
      >
        {data.map((item) => {
          const path = getProperty(item, "path");
          const title = getProperty(item, "title");
          const highlight = getProperty(item, "highlight");
          const image = getProperty(item, "image.childImageSharp.gatsbyImageData");

          return (
            <SwiperSlide key={path} className={styles.slide}>
              <Link to={path} className={styles.holder}>
                <div className={styles.image}>
                  <GatsbyImage image={image} alt={title} className={styles.img} />
                </div>
                <div className={styles.body}>
                  <Title wrapper="h4" size="s3" weight="w4" className={styles.title}>
                    {title}
                  </Title>
                </div>
                {highlight && ICON[locale]}
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Fragment>
  );
};

Carousel.defaultProps = {
  data: [],
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
