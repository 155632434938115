import PropTypes from "prop-types";

import { Container, Section, Title, Text, getProperty } from "@gh/shared";

import { Carousel } from "./components/offers/carousel";

import * as styles from "./offers.module.scss";

export const Offers = ({ data }) => {
  const title = getProperty(data, "title");
  const subtitle = getProperty(data, "subtitle");
  const content = getProperty(data, "content");

  const items = getProperty(data, "items") || [];

  return (
    <Section color="secondary">
      <Container>
        <div className={styles.header}>
          <Title size="s4" family="f2" weight="w4" transform="uppercase" color="primary">
            {title}
          </Title>
          <Title wrapper="h3" size="s7">
            {subtitle}
          </Title>
          <Text className={styles.text}>{content}</Text>
        </div>
      </Container>
      <div className={styles.carousel}>
        <Carousel data={items} />
      </div>
    </Section>
  );
};

Offers.defaultProps = {
  data: {},
};

Offers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
