import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Title, Text, ContainerFromMD, getProperty } from "@gh/shared";

import { VideoCover } from "../common/video-cover";

import { Stars } from "./components/teaser/stars";

import * as styles from "./teaser.module.scss";

export const Teaser = ({ data }) => {
  const title = getProperty(data, "logo.title");
  const subtitle = getProperty(data, "logo.subtitle");
  const slogan = getProperty(data, "slogan");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");
  const videos = getProperty(data, "videos");

  return (
    <Section noPaddingTop={true} noPaddingBottom={true}>
      <ContainerFromMD>
        <div className={styles.container}>
          <div className={styles.holder}>
            <div className={styles.logo}>
              <Stars />
              <Title wrapper="h1" size="s7" weight="w7" className={styles.title}>
                {title}
              </Title>
              <Text
                size="s3"
                family="f2"
                weight="w5"
                transform="uppercase"
                className={styles.subtitle}
              >
                {subtitle}
              </Text>
            </div>

            <Text size="s7" className={styles.slogan}>
              {slogan}
            </Text>
          </div>

          {videos ? (
            <VideoCover videos={videos} className={styles.video} />
          ) : image ? (
            <GatsbyImage image={image} alt="" className={styles.image} />
          ) : null}
        </div>
      </ContainerFromMD>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired,
    slogan: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
