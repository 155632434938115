// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "management-module--button--4M2+Y";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var content = "management-module--content--thtRq";
export var emblem = "management-module--emblem--dConz";
export var founder1 = "management-module--founder1--iW40U";
export var founder2 = "management-module--founder2--7LvyD";
export var founder3 = "management-module--founder3--Mv-zZ";
export var greeting = "management-module--greeting--hL1GY";
export var image = "management-module--image--24tjT";
export var text = "management-module--text--eDNM6";
export var wrapper = "management-module--wrapper--V44to";