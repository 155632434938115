// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "teaser-module--container--pztpw";
export var holder = "teaser-module--holder--cYVgM";
export var image = "teaser-module--image--S6AR9";
export var logo = "teaser-module--logo--b-ws-";
export var slogan = "teaser-module--slogan--Lo7tY";
export var subtitle = "teaser-module--subtitle--fHJBq";
export var title = "teaser-module--title--erTIw";
export var video = "teaser-module--video--ji35B";