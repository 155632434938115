// extracted by mini-css-extract-plugin
export var banner = "cuisine-module--banner--j0b2-";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "cuisine-module--container--Yo08h";
export var icon = "cuisine-module--icon--1EVZO";
export var image = "cuisine-module--image--OdB9Q";
export var main = "cuisine-module--main--mIBLa";
export var text = "cuisine-module--text--iOGkb";
export var title = "cuisine-module--title--nfatV";