import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  Container,
  Title,
  Text,
  Markdown,
  LinkButtonStars,
  ArrowCurvedIcon,
  getProperty,
} from "@gh/shared";

import * as styles from "./cuisine.module.scss";

export const Cuisine = ({ data }) => {
  const title = getProperty(data, "title");
  const banner = getProperty(data, "banner");
  const content = getProperty(data, "content");
  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");

  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section align="center">
      <Container>
        <div className={styles.container}>
          <div className={styles.banner}>
            <Text family="f3" size="s6" className={styles.text}>
              {banner}
            </Text>
            <ArrowCurvedIcon className={styles.icon} />
          </div>
          <div className={styles.main}>
            <Title color="primary" weight="w4" className={styles.title}>
              {title}
            </Title>
            <GatsbyImage image={image} className={styles.image} alt="" />
            <Text size="s5" className={styles.text}>
              <Markdown content={content} />
            </Text>
            <LinkButtonStars to={linkPath}>{linkText}</LinkButtonStars>
          </div>
        </div>
      </Container>
    </Section>
  );
};

Cuisine.defaultProps = {
  data: {},
};

Cuisine.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    banner: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
