// extracted by mini-css-extract-plugin
export var body = "carousel-module--body--gLEQR";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var holder = "carousel-module--holder--XCWhI";
export var image = "carousel-module--image--ZQPFx";
export var img = "carousel-module--img--rwUc0";
export var price = "carousel-module--price--ZeR6N";
export var slide = "carousel-module--slide--KzRQU";
export var slider = "carousel-module--slider--XMP1U";
export var text = "carousel-module--text--kWwly";
export var title = "carousel-module--title--4662N";