import PropTypes from "prop-types";

import { Section, Title, Text, Container, Markdown, getProperty } from "@gh/shared";

import { Carousel } from "./components/intro/carousel";

import * as styles from "./intro.module.scss";

export const Intro = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const images = getProperty(data, "images") || [];

  return (
    <Section>
      <Container>
        <Title size="s8">{title}</Title>
        <Text>
          <Markdown content={content} />
        </Text>
        <div className={styles.carousel}>
          <Carousel data={images} />
        </div>
      </Container>
    </Section>
  );
};

Intro.defaultProps = {
  data: {},
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
