// extracted by mini-css-extract-plugin
export var body = "carousel-module--body--H4eY0";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var buttons = "carousel-module--buttons--i0-BM";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var holder = "carousel-module--holder--h37Hg";
export var icon = "carousel-module--icon--J9Kyn";
export var image = "carousel-module--image--CGbW6";
export var img = "carousel-module--img--utod0";
export var locked = "carousel-module--locked--QyN7t";
export var next = "carousel-module--next--H-Ptw";
export var prev = "carousel-module--prev--Q4BHK";
export var slide = "carousel-module--slide--TZgiz";
export var slider = "carousel-module--slider--R-F2-";
export var title = "carousel-module--title--zJFOA";