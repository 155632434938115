import { useState } from "react";
import PropTypes from "prop-types";

import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./slider.module.scss";

export const Slider = ({ imageBack, imageFront }) => {
  const [value, setValue] = useState("50");

  const changeHandler = (event) => {
    const {
      target: { valueAsNumber },
    } = event;
    setValue(valueAsNumber);
  };

  return (
    <div className={styles.wrapper} style={{ "--value": `${value}%` }}>
      <div className={styles.back}>
        <GatsbyImage image={imageBack} alt="" />
      </div>
      <div className={styles.front}>
        <GatsbyImage image={imageFront} alt="" />
      </div>
      <input
        type="range"
        value={value}
        step="1"
        className={styles.slider}
        onChange={changeHandler}
      />
    </div>
  );
};

Slider.defaultProps = {
  imageBack: null,
  imageFront: null,
};

Slider.propTypes = {
  imageBack: PropTypes.object.isRequired,
  imageFront: PropTypes.object.isRequired,
};
