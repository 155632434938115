import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container, Title, Text, Markdown, LinkButtonStars, getProperty } from "@gh/shared";

import * as styles from "./management.module.scss";

export const Management = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const greeting = getProperty(data, "greeting");
  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");
  const emblem = getProperty(data, "emblem.childImageSharp.gatsbyImageData");
  const [person1, person2, person3] = getProperty(data, "persons") || [];

  const getPersonImage = (person) => getProperty(person, "image.childImageSharp.gatsbyImageData");
  const getPersonName = (person) => getProperty(person, "name");

  return (
    <Section>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.greeting}>
            <Text family="f3" size="s7" className={styles.text}>
              {greeting}
            </Text>
          </div>
          <div className={styles.emblem}>
            <GatsbyImage image={emblem} className={styles.image} alt="Familie Siller" />
          </div>
          <div className={styles.founder1}>
            <GatsbyImage
              image={getPersonImage(person1)}
              alt={getPersonName(person1)}
              className={styles.image}
            />
          </div>
          <div className={styles.founder2}>
            <GatsbyImage
              image={getPersonImage(person2)}
              alt={getPersonName(person2)}
              className={styles.image}
            />
          </div>
          <div className={styles.founder3}>
            <GatsbyImage
              image={getPersonImage(person3)}
              alt={getPersonName(person3)}
              className={styles.image}
            />
          </div>
          <div className={styles.content}>
            <Title>{title}</Title>
            <Text>
              <Markdown content={content} />
            </Text>
            <LinkButtonStars to={linkPath} className={styles.button}>
              {linkText}
            </LinkButtonStars>
          </div>
        </div>
      </Container>
    </Section>
  );
};

Management.defaultProps = {
  data: {},
};

Management.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    greeting: PropTypes.string.isRequired,
    emblem: PropTypes.object.isRequired,
    persons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
