import { Fragment } from "react";
import PropTypes from "prop-types";

import { Section, Title, Text, Markdown, LinkButtonStars, getProperty } from "@gh/shared";

import { ImageCardLeft } from "../common/image-card";

import { Slider } from "./components/activities/slider";
import { Carousel } from "./components/activities/carousel";

import * as styles from "./activities.module.scss";

export const Activities = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");
  const imageBack = getProperty(data, "images.back.childImageSharp.gatsbyImageData");
  const imageFront = getProperty(data, "images.front.childImageSharp.gatsbyImageData");

  const items = getProperty(data, "items") || [];

  return (
    <Section>
      <ImageCardLeft
        fullScreen={true}
        content={
          <Fragment>
            <Title wrapper="h3" size="s4" weight="w4" transform="uppercase">
              {title}
            </Title>
            <Text>
              <Markdown content={content} />
            </Text>
            <LinkButtonStars className={styles.button} to={linkPath}>
              {linkText}
            </LinkButtonStars>
          </Fragment>
        }
        image={<Slider imageBack={imageBack} imageFront={imageFront} />}
      />
      <Carousel data={items} />
    </Section>
  );
};

Activities.defaultProps = {
  data: {},
};

Activities.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    images: PropTypes.shape({
      back: PropTypes.object.isRequired,
      front: PropTypes.object.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};
