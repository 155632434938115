import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { Title, Text, getProperty, XS, SM, MD, LG, XL, XXL } from "@gh/shared";

import * as styles from "./carousel.module.scss";

export const Carousel = ({ data }) => (
  <Swiper
    spaceBetween={32}
    className={styles.slider}
    preventClicks={false}
    touchEventsTarget="container"
    preventClicksPropagation={false}
    centerInsufficientSlides={true}
    breakpoints={{
      [XS]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [SM]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [MD]: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      [LG]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      [XL]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      [XXL]: {
        slidesPerView: 6,
        slidesPerGroup: 6,
      },
    }}
    pagination={{
      clickable: true,
      lockClass: "carousel-offers-locked",
      bulletClass: "carousel-offers-bullet",
      horizontalClass: "carousel-offers-bullets",
      bulletActiveClass: "carousel-offers-bullet-active",
    }}
    modules={[Pagination]}
  >
    {data.map((item, index) => {
      const path = getProperty(item, "path");
      const title = getProperty(item, "title");
      const text = getProperty(item, "summary.text");
      const price = getProperty(item, "summary.price");
      const image = getProperty(item, "image_small.childImageSharp.gatsbyImageData");
      return (
        <SwiperSlide key={index} className={styles.slide}>
          <Link to={path} className={styles.holder}>
            <div className={styles.image}>
              <GatsbyImage image={image} alt={title} className={styles.img} />
            </div>
            <div className={styles.body}>
              <Title wrapper="h3" weight="w4" className={styles.title}>
                {title}
              </Title>
              <Text className={styles.text}>{text}</Text>
              <Text size="s2" family="f2" transform="uppercase" className={styles.price}>
                {price}
              </Text>
            </div>
          </Link>
        </SwiperSlide>
      );
    })}
  </Swiper>
);

Carousel.defaultProps = {
  data: [],
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      summary: PropTypes.shape({
        text: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
      }),
      image_small: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
};
