import { StarIcon } from "@gh/shared";

import * as styles from "./stars.module.scss";

const STARS_COUNT = 4;

export const Stars = () => (
  <div className={styles.wrapper}>
    {Array.from({ length: STARS_COUNT }).map((_, key) => (
      <StarIcon key={key} className={styles.icon} />
    ))}
  </div>
);
