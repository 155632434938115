import PropTypes from "prop-types";

import { Section } from "@gh/shared";

import { Carousel } from "./components/reviews/carousel";

export const Reviews = ({ data }) => (
  <Section color="primary">
    <Carousel data={data} />
  </Section>
);

Reviews.defaultProps = {
  data: [],
};

Reviews.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
