import { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Title, Text, Markdown, LinkButtonStars, getProperty } from "@gh/shared";

import { ImageCardLeft } from "../common/image-card";

import * as styles from "./spa.module.scss";

export const Spa = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section>
      <ImageCardLeft
        content={
          <Fragment>
            <Title>{title}</Title>
            <Text>
              <Markdown content={content} />
            </Text>
            <LinkButtonStars className={styles.button} to={linkPath}>
              {linkText}
            </LinkButtonStars>
          </Fragment>
        }
        image={<GatsbyImage image={image} alt="" />}
      />
    </Section>
  );
};

Spa.defaultProps = {
  data: {},
};

Spa.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
