// extracted by mini-css-extract-plugin
export var back = "slider-module--back--r2rcd";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var front = "slider-module--front--Ix+zH";
export var slider = "slider-module--slider--ORraX";
export var wrapper = "slider-module--wrapper--A+Ovp";