import { Fragment } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  Title,
  Text,
  Markdown,
  LinkButtonStars,
  ApartmentIconDE,
  ApartmentIconEN,
  getProperty,
  useLocale,
} from "@gh/shared";

import { ImageCardRight } from "../common/image-card";

import * as styles from "./apartments.module.scss";

const ICON = {
  de: <ApartmentIconDE className={styles.icon} />,
  en: <ApartmentIconEN className={styles.icon} />,
};

export const Apartments = ({ data }) => {
  const { locale } = useLocale();
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const linkText = getProperty(data, "link.text");
  const linkPath = getProperty(data, "link.path");
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");

  return (
    <Section>
      <ImageCardRight
        fullScreen={true}
        content={
          <Fragment>
            <Title wrapper="h3" size="s4" weight="w4" transform="uppercase">
              {title}
            </Title>
            <Text>
              <Markdown content={content} />
            </Text>
            <LinkButtonStars className={styles.button} to={linkPath}>
              {linkText}
            </LinkButtonStars>
          </Fragment>
        }
        image={
          <div className={styles.image}>
            <GatsbyImage image={image} alt="" />
            {ICON[locale]}
          </div>
        }
      />
    </Section>
  );
};

Apartments.defaultProps = {
  data: {},
};

Apartments.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.object.isRequired,
  }).isRequired,
};
