import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import { Container, Text, getProperty } from "@gh/shared";

import { ButtonNext } from "../../../common/nav-button";

import * as styles from "./carousel.module.scss";

const nextEl = `.${styles.button}`;

export const Carousel = ({ data }) => (
  <div className={styles.wrapper}>
    <Container>
      <Swiper
        loop={true}
        spaceBetween={0}
        modules={[Pagination, Navigation]}
        navigation={{ nextEl }}
        pagination={{
          clickable: true,
          lockClass: 'carousel-reviews-locked',
          bulletClass: 'carousel-reviews-bullet',
          horizontalClass: 'carousel-reviews-bullets',
          bulletActiveClass: 'carousel-reviews-bullet-active'
        }}
      >
        {data.map((item, index) => {
          const name = getProperty(item, "name");
          const text = getProperty(item, "text");

          return (
            <SwiperSlide key={index}>
              <Text size="s4">{text}</Text>
              <ButtonNext className={styles.button} />
              <Text size="s2" family="f2" color="white" transform="uppercase">
                {name}
              </Text>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  </div>
);

Carousel.defaultProps = {
  data: [],
};

Carousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
