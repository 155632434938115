import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { useActivitiesLatest, useOffersLatest } from "hooks";

import { Meta } from "./common/meta";
import { Teaser } from "./home/teaser";
import { Intro } from "./home/intro";
import { Offers } from "./home/offers";
import { Management } from "./home/management";
import { Rooms } from "./home/rooms";
import { Cuisine } from "./home/cuisine";
import { Spa } from "./home/spa";
import { Reviews } from "./home/reviews";
import { Activities } from "./home/activities";
import { Apartments } from "./home/apartments";

const Template = ({ data }) => {
  const offersLatest = useOffersLatest();
  const activitiesLatest = useActivitiesLatest();

  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const teaser = getProperty(data, "markdownRemark.frontmatter.home.teaser");
  const intro = getProperty(data, "markdownRemark.frontmatter.home.intro");
  const offers = {
    ...getProperty(data, "markdownRemark.frontmatter.home.offers"),
    items: getProperty(offersLatest, "offers.items"),
  };
  const management = getProperty(data, "markdownRemark.frontmatter.home.management");
  const rooms = getProperty(data, "markdownRemark.frontmatter.home.rooms");
  const cuisine = getProperty(data, "markdownRemark.frontmatter.home.cuisine");
  const spa = getProperty(data, "markdownRemark.frontmatter.home.spa");
  const reviews = getProperty(data, "markdownRemark.frontmatter.home.reviews");
  const activities = {
    ...getProperty(data, "markdownRemark.frontmatter.home.activities"),
    items: getProperty(activitiesLatest, "activities.items"),
  };
  const apartments = getProperty(data, "markdownRemark.frontmatter.home.apartments");

  return (
    <Fragment>
      <Meta data={meta} />
      <Teaser data={teaser} />
      <Intro data={intro} />
      <Offers data={offers} />
      <Management data={management} />
      <Rooms data={rooms} />
      <Cuisine data={cuisine} />
      <Spa data={spa} />
      <Reviews data={reviews} />
      <Activities data={activities} />
      <Apartments data={apartments} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title

        meta {
          title
          description
        }

        home {
          teaser {
            logo {
              title
              subtitle
            }
            slogan
            content
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            videos {
              publicURL
              extension
            }
          }

          intro {
            title
            content
            images {
              childImageSharp {
                gatsbyImageData(width: 750, layout: CONSTRAINED)
              }
            }
          }

          offers {
            title
            subtitle
            content
          }

          management {
            title
            content
            greeting
            link {
              text
              path
            }
            emblem {
              childImageSharp {
                gatsbyImageData(width: 300, layout: CONSTRAINED)
              }
            }
            persons {
              name
              image {
                childImageSharp {
                  gatsbyImageData(width: 200, layout: CONSTRAINED)
                }
              }
            }
          }

          rooms {
            title
            content
            link {
              text
              path
            }
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }

          cuisine {
            title
            content
            link {
              text
              path
            }
            banner
            image {
              childImageSharp {
                gatsbyImageData(width: 300, height: 400, layout: CONSTRAINED)
              }
            }
          }

          spa {
            title
            content
            link {
              text
              path
            }
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 950)
              }
            }
          }

          reviews {
            name
            text
          }

          activities {
            content
            link {
              path
              text
            }
            title
            images {
              back {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              front {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }

          apartments {
            title
            content
            link {
              text
              path
            }
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
